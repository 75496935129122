<template>
	<div>
		<el-form ref="queryForm" :inline="true" :model="queryForm" size="small">
			<el-form-item label="司机姓名" prop="key">
				<el-input v-model="queryForm.key" clearable placeholder="名称/车牌"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button @click="handleSearch" type="primary">查询</el-button>
			</el-form-item>
		</el-form>

		<el-table :data="tableData" v-loading="tableLoading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}"
			border stripe size="small" style="width: 100%;" max-height="500px">
			<el-table-column label="#" type="index" width="60"></el-table-column>
			<el-table-column label="姓名" prop="name"></el-table-column>
			<el-table-column label="手机号" prop="mobile"></el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total, jumper" :total="total"
				:current-page.sync="queryForm.page" :page-size="pageSize" @current-change="getDriverData">
			</el-pagination>
			<el-button size="small">确定</el-button>
		</div>
	</div>
</template>

<script>
	import {
		getDriverDataAPI,
	} from '@/api/dispatch-vehicle.js'

	export default {
		name: 'Driver',
		data() {
			return {
				queryForm: {
					page: 1,
					key: '',
				},
				tableData: [],
				total: 0,
				pageSize: 0,
				tableLoading: false,
			}
		},
		created() {
			this.getDriverData()
		},
		methods: {
			// 查询按钮
			handleSearch() {
				this.queryForm.page = 1
				this.getDriverData()
			},
			// 获取司机数据
			getDriverData() {
				this.tableLoading = true

				getDriverDataAPI(this.queryForm).then(res => {
					this.tableData = res.data
					this.total = res.total
					this.pageSize = res.per_page
					this.tableLoading = false
				})
			}
		}
	}
</script>

<style scoped>
</style>
